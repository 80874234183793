<template>
  <tr>
    <template v-for="requirementValue in requirementTourPosition.requirementValues?.sort((a, b) => a.requirementAttribute.sortorder - b.requirementAttribute.sortorder)">
      <RequirementValueItem
        :requirementValue="requirementValue"
        :allConcertsForTour="allConcertsForTour"
      ></RequirementValueItem>
    </template>
    <td style="width: 1%; white-space: nowrap; vertical-align: top;" class="pt-1">
      <div class="d-flex">
        <DeleteButton
          @callback="deletePosition(requirementTourPosition.id)"
        ></DeleteButton>

        <HistoryButton
          class="ml-2"
          :historyItems="historyItems()"
        ></HistoryButton>
      </div>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import RequirementTourPosition from "@/interfaces/RequirementTourPosition";
import DeleteButton from "@/components/DeleteButton.vue";
import RequirementValueItem from "@/components/RequirementValueItem.vue";
import Concert from "@/interfaces/Concert";
import HistoryButton from "@/components/HistoryButton.vue"
import {Ref, ref}    from "vue"
import HistoryItem   from "@/interfaces/HistoryItem"

const props = defineProps<{
  requirementTourPosition: RequirementTourPosition,
  allConcertsForTour: Concert[]
}>()

const historyItems = () => {
  const items: Ref<HistoryItem[]> = ref([])
  props.requirementTourPosition.requirementValues.forEach(item => {
    if (item.requirementAttribute.requirementAttributeType.datatype === 'file') {
      if (item.requirementValueFiles.length > 0) {
        let lastChangeItem = item.requirementValueFiles.reduce((prev, current) =>
            prev.lastChange > current.lastChange ? prev : current
        )

        const historyItem: Ref<HistoryItem> = ref({})
        historyItem.value.fieldName = item.requirementAttribute.name
        historyItem.value.changeText = lastChangeItem.lastChangeText
        items.value.push(historyItem.value)
      }
    } else if (item.lastChangeText) {
      const historyItem: Ref<HistoryItem> = ref({})
      historyItem.value.fieldName = item.requirementAttribute.name
      historyItem.value.changeText = item.lastChangeText
      items.value.push(historyItem.value)
    }
  })
  return items.value
}

const emits = defineEmits(["deletePosition"])

const deletePosition = async (requirementTourPositionId: number) => {
  emits("deletePosition", requirementTourPositionId)
}
</script>

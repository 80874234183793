<template>
  <template v-if="requirementValue.requirementAttribute.requirementAttributeType.systemKey === 'concert'">
    <td style="min-width: 250px; vertical-align: top;" class="pt-1">
      <v-select
        v-model="requirementValue.valueConId"
        :items="concertItems()"
        item-title="name"
        item-value="id"
        density="compact"
        @update:modelValue="saveRequirementValue"
        :label="requirementValue.requirementAttribute.name"
        :class="saveClass"
        :hint="saveHint"
        :persistent-hint="showHint"
      >
        <template #selection="{ item }">
          <span>{{item.raw.date}} {{ (item.raw.begin ? '- ' + item.raw.begin : '') }} </span>
        </template>

        <template #item="{ item, props }">
          <v-list-item v-bind="props">
            <template #title>
              <span>{{item.raw.date}}  {{(item.raw.begin ? '- ' + item.raw.begin : '')}} {{(item.raw.city ? '- ' + item.raw.city : '')}}</span>
            </template>
          </v-list-item>
        </template>
      </v-select>
    </td>
    <td style="min-width: 150px;" class="pb-4">{{ selectedConcert?.city ?? "-" }}</td>
    <td style="min-width: 200px;" class="pb-4">{{ selectedConcert?.venue ?? "-" }}</td>
    <td style="min-width: 200px" class="pb-4">{{ selectedConcert?.promoter ?? "-" }}</td>
  </template>

  <template v-else>
    <td :style="style(requirementValue)" style="vertical-align: top;" class="pt-1">
      <template v-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'string'">
        <v-text-field
          v-model="requirementValue.valueString"
          :label="requirementValue.requirementAttribute.name"
          density="compact"
          @blur="saveRequirementValue"
          maxlength="250"
          :class="saveClass"
          :hint="saveHint"
          :persistent-hint="showHint"
        >
        </v-text-field>
      </template>

      <template v-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'string_long'">
        <v-textarea
          rows="1"
          auto-grow
          v-model="requirementValue.valueString"
          :label="requirementValue.requirementAttribute.name"
          density="compact"
          @blur="saveRequirementValue"
          maxlength="4000"
          :class="saveClass"
          :hint="saveHint"
          :persistent-hint="showHint"
        ></v-textarea>
      </template>

      <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'item'">
        <v-select
          v-model="requirementValue.valueRatiId"
          :items="requirementValue.requirementAttribute.requirementAttributeType.requirementAttributeTypeItems"
          item-title="value"
          item-value="id"
          :label="requirementValue.requirementAttribute.name"
          density="compact"
          @update:modelValue="saveRequirementValue"
          :class="saveClass"
          :hint="saveHint"
          :persistent-hint="showHint"
        ></v-select>
      </template>

      <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'bool'">
        <v-checkbox
          v-model="requirementValue.valueBool"
          density="compact"
          @change="saveRequirementValue"
          :class="saveClass"
          :hint="saveHint"
          :persistent-hint="showHint"
        >
        </v-checkbox>
      </template>

      <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'file'">
        <div>
          <div style="display: flex; align-items: flex-start;">
            <v-file-input
              v-model="file"
              :clearable="false"
              :accept="allowedMimeTypes.toString()"
              label="Datei (.jpg, .jpeg, .png, .pdf)"
              prepend-icon=""
              density="compact"
              @change="uploadFile"
              :class="saveClass"
              :hint="saveHint"
              :persistent-hint="showHint"
            ></v-file-input>
          </div>
          <div v-if="requirementValue.requirementValueFiles.length > 0">
            <hr style="color: #fafafa">
            <div style="font-style: italic">Hinterlegte Dateien:</div>
            <template v-for="requirementValueFile in requirementValue.requirementValueFiles">
              <RequirementValueFileItem
                :requirementValueFile="requirementValueFile"
                @deleteFile="deleteFile"
              ></RequirementValueFileItem>
            </template>
          </div>
        </div>
      </template>

      <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'int'">
        <DebouncedNumberInput
          v-model="requirementValue.valueInt"
          control-variant="split"
          :label="requirementValue.requirementAttribute.name"
          :min="1"
          density="compact"
          :clearable="true"
          :persistent-counter="true"
          @callback="saveRequirementValue"
          :class="saveClass"
          :hint="saveHint"
          :persistent-hint="showHint"
        ></DebouncedNumberInput>
      </template>

      <template v-else-if="requirementValue.requirementAttribute.requirementAttributeType.datatype === 'price'">
        <CurrencyInput
          v-model="requirementValue.valueFloat"
          :options="{ precision: { min: 2, max: 2 } }"
          :currency="requirementValue.currency"
          :compact="true"
          :label="requirementValue.requirementAttribute.name"
          @blur="saveRequirementValue"
          :extra-class="saveClass"
          :hint="saveHint"
        >
        </CurrencyInput>
      </template>
    </td>
  </template>
</template>

<script lang="ts" setup>
import {computed, ref}          from "vue"
import RequirementValue         from "@/interfaces/RequirementValue"
import CurrencyInput            from "@/components/CurrencyInput.vue"
import Concert                  from "@/interfaces/Concert"
import RequirementService       from "@/api/RequirementService"
import DebouncedNumberInput     from "@/components/DebouncedNumberInput.vue"
import RequirementValueFileItem from "@/components/RequirementValueFileItem.vue"
import {useStore}               from "vuex"

const store = useStore()

const props = defineProps<{
  requirementValue: RequirementValue,
  allConcertsForTour: Concert[]
}>()

const emits = defineEmits(["deletePosition"])

const showSaveIcon = ref(false)

const requirementService = ref(new RequirementService())

const selectedConcert = computed(() => props.allConcertsForTour.find(concert => concert.id === props.requirementValue.valueConId))

const saveClass = ref()
const saveHint = ref()
const showHint = ref(false)

const allowedMimeTypes = ["image/jpeg", "image/png", "application/pdf"]
const file = ref()

const deletePosition = async (requirementTourPositionId: number) => {
  emits("deletePosition", requirementTourPositionId)
}

const deleteFile = async (id: number) => {
  requirementService.value.deleteFile(id).then(data => {
    if (data.data.success) {
      props.requirementValue.requirementValueFiles = props.requirementValue.requirementValueFiles.filter(item => {
        return item.id !== id
      })
      store.commit("setSuccessNotification", "Die Datei wurde erfolgreich gelöscht.")
    }
  })
}

const saveRequirementValue = async () => {
  await requirementService.value.saveRequirementValue(props.requirementValue)
    .then(data => {
      if (data.success) {
        props.requirementValue.lastChange = data.data.requirementValue.lastChange
        props.requirementValue.lastChangeText = data.data.requirementValue.lastChangeText
        showSaveIcon.value = true
      }
    })

  showSavedHint()
}

const uploadFile = async () => {
  await requirementService.value.uploadFile(props.requirementValue, file.value).then(data => {
    if (data.data.success) {
      props.requirementValue.requirementValueFiles.push(data.data.requirementValueFile)
      file.value = null
      const activeElement = document.activeElement
      if (activeElement) {
        activeElement.blur()
      }

      showSavedHint("Erfolgreich hochgeladen")
    } else {
      if (data.data.errorMessage) {
        alert(data.data.errorMessage)
      }
    }
  })
}

const showSavedHint = (hintText?: string) => {
  showHint.value = true
  saveHint.value = hintText ?? "Gespeichert"
  saveClass.value = "saveClass"
  setTimeout(() => {
    showHint.value = false
    saveHint.value = null
  }, 1000)
  setTimeout(() => {
    saveClass.value = null
  }, 1200)
}

const style = (requirementValue: RequirementValue) => {
  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "int") {
    return "width: 200px; min-width: 200px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "bool") {
    return "width: 100px; min-width: 100px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "float") {
    return "width: 175px; min-width: 175px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "price") {
    return "width: 200px; min-width: 200px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "string") {
    return "width: 250px; min-width: 250px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "string_long") {
    return "width: 300px; min-width: 300px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "item") {
    return "width: 250px; min-width: 200px; white-space: nowrap;"
  }

  if (requirementValue.requirementAttribute.requirementAttributeType.datatype === "file") {
    return "width: 200px;  min-width: 200px; max-width: 200px; white-space: nowrap;"
  }

  return ""
}

const concertItems = () => {
  return props.allConcertsForTour
}
</script>

<style scoped>
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}
</style>
<style>
.saveClass .v-messages {
  color: green !important;
}
</style>

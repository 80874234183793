<template>
  <v-container v-if="!isLoading && incomingInvoice" class="pt-10">
    <div v-if="isSaved" class="text-center">
      <div class="text-success">Ihre Eingangsrechnung wurde erfolgreich erfasst und per E-Mail versendet.</div>
      <br />

      <div>
        <v-btn @click="reloadPage">Neue Eingangsrechnung anlegen</v-btn>
        &nbsp;
        <v-btn @click="backToSystem">Zurück zum System</v-btn>
      </div>
    </div>
    <v-form @submit.prevent="saveInvoice" ref="myForm" v-model="isValid" v-else>
      <v-row>
        <v-col>
          <v-row dense>
            <v-col>
              <h3>Eingangsrechnungserfassung</h3>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <div class="dp-block">
                <div class="dp-field">
                  <label class="dp-label">Rechnungs-Datum *</label>

                  <Datepicker
                    v-model="incomingInvoice.date"
                    locale="de"
                    modelType="dd.MM.yyyy"
                    :format-locale="de"
                    format="dd.MM.yyyy"
                    name="incomingInvoice.date"
                    :enableTimePicker="false"
                    :clearable="false"
                    :rules="incomingInvoiceDateRule"
                    textInput
                    autoApply
                    :textInputOptions="{format: 'dd.MM.yyyy'}"
                    required
                  ></Datepicker>
                </div>

                <div class="v-input__details">
                  <div class="v-messages"></div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                label="Kreditkarte"
                v-model="incomingInvoice.creditCard"
                :items="availableCreditCards"
                item-title="fullname"
                item-value="id"
                :no-data-text="noResultsText"
                :menu-props="{maxHeight: 320, eager: true}"
                hint="Info: Wenn eine Kreditkarte hinterlegt wird, gilt diese Rechnung bereits als bezahlt und es wird keine E-Mail an die Buchhaltung gesendet"
                :persistent-hint="true"
                class="info-text-grey"
                clearable
                cache-items
                return-object
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense v-if="!incomingInvoice.creditCard">
            <v-col>
              <div class="dp-block info-text-grey">
                <div class="dp-field">
                  <label class="dp-label">An Buchhaltung übergeben</label>

                  <Datepicker
                    v-model="incomingInvoice.dateSentToAccounting"
                    locale="de"
                    modelType="dd.MM.yyyy"
                    :format-locale="de"
                    format="dd.MM.yyyy"
                    name="incomingInvoice.dateSentToAccounting"
                    :enableTimePicker="false"
                    :clearable="true"
                    textInput
                    autoApply
                    :textInputOptions="{format: 'dd.MM.yyyy'}"
                  ></Datepicker>
                </div>

                <div class="v-input__details">
                  <div class="v-messages">
                    <div class="v-messages__message" style="transform-origin: center top 0px;">Info: Wenn bei "An Buchhaltung übergeben" ein Datum eingetragen wird, wird keine E-Mail an die Buchhaltung gesendet</div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <div class="dp-block">
                <div class="dp-field">
                  <label class="dp-label">Zahlungsziel</label>

                  <Datepicker
                    v-model="incomingInvoice.dateOfPayment"
                    locale="de"
                    modelType="dd.MM.yyyy"
                    :format-locale="de"
                    format="dd.MM.yyyy"
                    name="incomingInvoice.dateOfPayment"
                    :enableTimePicker="false"
                    :clearable="true"
                    textInput
                    autoApply
                    :textInputOptions="{format: 'dd.MM.yyyy'}"
                  ></Datepicker>
                </div>

                <div class="v-input__details">
                  <div class="v-messages"></div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                label="Rechnungs-Nummer *"
                v-model="incomingInvoice.number"
                :rules="incomingInvoiceNumberRule"
                @change="checkDuplicate()"
                required
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-autocomplete
                label="Rechnungskopie-Empfänger"
                v-model="incomingInvoice.copyRecipients"
                v-model:search="copyRecipientSearch"
                :loading="copyRecipientLoading"
                :items="copyRecipientItems"
                item-title="name"
                item-value="id"
                :custom-filter="customFilter"
                :no-data-text="noResultsText"
                :menu-props="{maxHeight: 320, eager: true}"
                hint="Info: Ausgegraute Einträge haben keine hinterlegte E-Mail-Adresse und können nicht als Rechnungskopie-Empfänger gewählt werden"
                :persistent-hint="true"
                class="info-text-grey"
                cache-items
                clearable
                return-object
                multiple
                chips
                closable-chips
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense v-if="!incomingInvoice.dateSentToAccounting && !incomingInvoice.creditCard">
            <v-col>
              <v-autocomplete
                label="Freigabe anfragen bei (E-Mail)"
                v-model="incomingInvoice.emailApproval"
                v-model:search="personSearch"
                :loading="personLoading"
                :items="personItems"
                item-title="name"
                item-value="id"
                :custom-filter="customFilter"
                :no-data-text="noResultsText"
                :menu-props="{maxHeight: 320, eager: true}"
                hint="Info: Ausgegraute Einträge haben keine hinterlegte E-Mail-Adresse und können nicht als Freigabe-Empfänger gewählt werden"
                :persistent-hint="true"
                class="info-text-grey"
                cache-items
                clearable
                return-object
                multiple
                chips
                closable-chips
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="10">
              <v-autocomplete
                label="Rechnungssteller *"
                v-model="incomingInvoice.company"
                v-model:search="companySearch"
                :rules="incomingInvoiceCompanyRule"
                :loading="companyLoading"
                :items="companyItems"
                item-title="name"
                item-value="id"
                :custom-filter="customFilter"
                :no-data-text="noResultsText"
                :menu-props="{maxHeight: 320, eager: true}"
                @update:modelValue="checkDuplicate()"
                cache-items
                clearable
                return-object
              ></v-autocomplete>
            </v-col>

            <v-col>
              <v-btn
                size="large"
                icon="mdi-plus"
                target="_blank"
                @click="linkToCompanyCreate()"
              >
                <v-icon class="mdi-plus"></v-icon>
                <v-tooltip
                  activator="parent"
                  location="top"
                >
                  Neue Firma anlegen
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense v-if="incomingInvoice.company && !incomingInvoice.company.address">
            <v-col cols="12" class="mb-5">
              <div style="border: 1px solid #B00020;" class="pa-3">
                <span>Achtung, zu dem gewählten Rechnungssteller ist keine Adresse hinterlegt.</span>

                <v-btn
                  size="x-small"
                  target="_blank"
                  @click="linkToSenderEdit()"
                  class="float-right"
                >
                  Firma bearbeiten
                </v-btn>

                <div class="mt-2">Nach Bearbeiten der Firma kann diese neu gesucht werden.</div>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-select
                label="Rechnungsempfänger *"
                v-model="incomingInvoice.recipientCompany"
                item-title="name"
                item-value="id"
                :items="possibleRecipients()"
                :menu-props="{maxHeight: 320, eager: true}"
                return-object
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense v-if="showDuplicateWarning">
            <v-col>
              <div class="border pa-2 mb-5">
                <v-icon icon="mdi-alert-outline" class="pr-1"></v-icon>
                Zu dem eingegebenen Rechnungssteller wurde unter der eingegebenen Rechnungsnummer bereits eine Rechnung erfasst!
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-textarea
                ref="tempSelectedElem"
                label="Beschreibung"
                v-model="incomingInvoice.desc"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                label="Währung"
                v-model="incomingInvoice.currency"
                :items="availableCurrencies"
                item-title="desc"
                item-value="id"
                :no-data-text="noResultsText"
                :menu-props="{maxHeight: 320, eager: true}"
                cache-items
                return-object
              ></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <CurrencyInput
                v-model="incomingInvoice.totalAmount"
                :options="{ }"
                :currency="incomingInvoice.currency"
                label="Gesamtsumme (netto) *"
              />
            </v-col>
          </v-row>

          <IncomingInvoiceGroups :incoming-invoice="incomingInvoice" :available-invoice-groups="availableInvoiceGroups"></IncomingInvoiceGroups>

          <v-row dense>
            <v-col>
              <v-autocomplete
                label="Tournee"
                v-model="selectedTour"
                v-model:search="tourSearch"
                :loading="tourLoading"
                :items="tourItems"
                item-title="name"
                item-value="id"
                @update:modelValue="addTour"
                :custom-filter="customFilter"
                :no-data-text="noResultsText"
                :menu-props="{maxHeight: 320, eager: true}"
                return-object
                clearable
              ></v-autocomplete>
              <input v-if="showFocusElem" ref="tourSelectedElem">
            </v-col>
          </v-row>
        </v-col>

        <v-col>
          <IncomingInvoiceUploads :incoming-invoice="incomingInvoice" :available-currencies="availableCurrencies" :check-duplicate="checkDuplicate"></IncomingInvoiceUploads>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <IncomingInvoiceTourTabs :incoming-invoice="incomingInvoice"></IncomingInvoiceTourTabs>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <IncomingInvoiceCostAssignment :incoming-invoice="incomingInvoice"></IncomingInvoiceCostAssignment>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-textarea
            :class="incomingInvoice.incomingInvoiceTours.length > 0 ? 'mt-6' : ''"
            label="E-Mail-Freitext"
            v-model="incomingInvoice.emailText"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="errors.length > 0">
        <v-col>
          <div class="border pa-2 mt-6">
            <v-icon icon="mdi-alert-outline" class="pr-1"></v-icon>
            Eingangsrechnung kann nicht gespeichert werden:
            <template v-for="error in errors">
              <p>- {{ error }}</p>
            </template>
          </div>
        </v-col>
      </v-row>

      <div class="mt-6">
        <v-btn
          @click="saveInvoice()"
          color="success"
          :disabled="isSaving"
        >
          <span
            class="pr-2"
            v-if="isSaving"
          >
            <v-icon
              icon="fa:fas fa-rotate fa-spin"
            ></v-icon>
          </span>
          Speichern und versenden
          <v-tooltip
            activator="parent"
            location="top"
            v-if="errors.length > 0"
          >
            Eingangsrechnung kann nicht gespeichert werden
          </v-tooltip>
        </v-btn>

        <v-btn
          @click="backToSystem"
          class="ml-2"
        >
          Abbrechen und zurück
        </v-btn>

        <v-btn
          @click="debugButton"
          class="ml-2"
          v-if="debugEnabled"
        >
          Debug-Test
        </v-btn>

      </div>
    </v-form>
  </v-container>
</template>

<style>
.dp-field {
  height: 56px;
  background: #F6F6F6;
  border-bottom: 1px solid #A5A5A5;
  opacity: 1;
  /*margin-bottom: 22px;*/
  border-radius: 2px 2px 0 0;
}

.v-theme--myCustomDarkTheme .dp-field {
  background: #2A3339;
  border-bottom: 1px solid #70767A;
}

.v-theme--myCustomDarkTheme .dp-field input {
  color: white;
}

.dp-field:hover {
  height: 56px;
  background: #EDEDED;
  border-bottom: 1px solid #3A3A3A;
  opacity: 1;
}

.v-theme--myCustomDarkTheme .dp-field:hover {
  background: #323B40;
  border-bottom: 1px solid #CDCFD1;
}

.dp-label {
  top: 7px;
  font-size: 12px;
  padding: 0 16px;
  color: #666
}

.v-theme--myCustomDarkTheme .dp-label {
  color: rgba(255, 255, 255, 0.87);
}

.dp__input.dp__input_icon_pad {
  border: none;
  border-radius: 0;
  background: transparent;
  padding: 0 12px 6px 35px;
}

.dp__icon.dp__input_icon.dp__input_icons {
  padding: 2px 12px 6px 12px;
}

a.v-btn, a.v-btn:hover {
  text-decoration: none;
}

.dp-block .v-input__details {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

i.fa-spin {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-text-grey .v-messages__message {
  color: #666;
}
</style>

<script lang="ts">

import {useRoute} from "vue-router";
import {useStore} from "vuex";
import { computed, onMounted, reactive, Ref, ref, watch } from "vue"
import IncomingInvoiceService from "@/api/IncomingInvoiceService";
import IncomingInvoice from "@/interfaces/IncomingInvoice";
import IncomingInvoiceTour from "@/interfaces/IncomingInvoiceTour";
import IncomingInvoiceGroups from "@/components/IncomingInvoiceGroups.vue";
import IncomingInvoiceUploads from "@/components/IncomingInvoiceUploads.vue";
import IncomingInvoiceTourTabs from "@/components/IncomingInvoiceTourTabs.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import IncomingInvoicePosition from "@/interfaces/IncomingInvoicePosition";
import CurrencyService from "@/api/CurrencyService";
import IncomingInvoiceCostAssignment from "@/components/IncomingInvoiceCostAssignment.vue";
import IncomingInvoiceGroup from "@/interfaces/IncomingInvoiceGroup";
import Company from "@/interfaces/Company";
import CreditCard from "@/interfaces/CreditCard"
import CreditCardService from "@/api/CreditCardService"
import {de} from "date-fns/locale";

export default {
  computed: {
    de() {
      return de
    }
  },
  components: {IncomingInvoiceCostAssignment, CurrencyInput, IncomingInvoiceTourTabs, IncomingInvoiceUploads, IncomingInvoiceGroups },
  setup() {
    onMounted(async () => {
      await init()

      // not a pretty solution, but has to be this way - otherwise watch() is initialised before subcomponents are loaded
      // so watch is immediately triggered
      // can't be worked around by watching if the model actually changed either because loading the subcomponents does change it
      setTimeout(function () {
        watch(() => incomingInvoice,
          () => {
            store.commit('setDirty')
          },
          { deep: true }
        );
      }, 300)

      watch(() => incomingInvoice,
        (currentValue) => {
          checkForErrors()
        },
        { deep: true, immediate: true }
      );
    })

    const route = useRoute()
    const store = useStore()

    const debugEnabled = ref(false)

    const myForm = ref(null)
    const isValid = ref(true)

    const selectedTour = ref()
    const tourSelectedElem = ref()
    const showFocusElem = ref(false)

    const incomingInvoiceService = ref(new IncomingInvoiceService())
    const currencyService = ref(new CurrencyService())
    const creditCardService = ref(new CreditCardService())

    const isSaved = ref(false)
    const isSaving = ref(false)

    let error = reactive({} as Error)

    let incomingInvoice = reactive({} as IncomingInvoice)
    incomingInvoice.date = getCurrentDate();
    incomingInvoice.incomingInvoiceTours = [];
    incomingInvoice.senderCompany = reactive({} as Company)

    const availableInvoiceGroups = ref();
    const availablePossibleRecipients = ref();
    const availableCurrencies = ref();
    const availableCreditCards: Ref<CreditCard[]> = ref([]);

    const isLoading = ref(true)

    const personLoading = ref(false)
    const personItems = ref()
    const personSearch = ref()
    const personSearchTimer = ref()

    const copyRecipientLoading = ref(false)
    const copyRecipientItems = ref()
    const copyRecipientSearch = ref()
    const copyRecipientSearchTimer = ref()

    const companyLoading = ref(false)
    const companyItems = ref()
    const companySearch = ref()
    const companySearchTimer = ref()

    const tourLoading = false
    const tourItems = ref()
    const tourSearch = ref()
    const tourSearchTimer = ref()

    const showDuplicateWarning = ref(false)

    const noResultsText = "Keine Ergebnisse"

    const errors = ref([])
    checkForErrors()

    const guid = computed(() => {
      return route.query.guid;
    });

    const init = async () => {
      await incomingInvoiceService.value.getGroups().then(data => {
        availableInvoiceGroups.value = data.data;
      })

      await incomingInvoiceService.value.findPossibleRecipientCompanies().then(data => {
        availablePossibleRecipients.value = data.data;
        if (data.data !== null) {
          incomingInvoice.recipientCompany = data.data[0];
        }
      })

      await creditCardService.value.getCreditCards().then(data => {
        if (data.success) {
          availableCreditCards.value = data.data;
        }
      })

      await currencyService.value.getCurrencies().then(data => {
        availableCurrencies.value = data.data;

        let currenciesEuro = availableCurrencies.value.filter((item) => {
          return item.iso3 === 'EUR'
        })

        incomingInvoice.currency = currenciesEuro[0]
      })

      let urlParams = new URLSearchParams(window.location.search)
      let iinIdString = urlParams.get('id');
      if (iinIdString !== null) {
        // remove param from URI - both to make it prettier and to only duplicate once
        const newUri = window.location.href.replace(/id=.*?$/i, '');
        window.history.replaceState({}, document.title, newUri);

        // load incoming invoice from ID
        let iinId = parseInt(iinIdString)
        await loadIncomingInvoiceFromServer(iinId)
      }

      isLoading.value = false
    }

    // init functions
    const loadIncomingInvoiceFromServer = async (iinId) => {
      await incomingInvoiceService.value.getIncomingInvoiceData(iinId).then(async data => {
        if (data.success) {
          loadInvoiceValues(data.data)

          loadInvoiceGroups(data.data.incomingInvoiceGroups)

          loadInvoiceToursAndConcerts(data.data.incomingInvoiceTours)

          await loadInvoicePdf(data.data.invoicePdf)

          await loadInvoiceAttachments(data.data.attachments)
        }
      })
    }

    const loadInvoiceValues = (values) => {
      // fill invoice itself
      incomingInvoice.date = values.date
      incomingInvoice.desc = values.desc
      incomingInvoice.totalAmount = values.totalAmount
      incomingInvoice.number = values.number
      incomingInvoice.company = { id: values.comId, name: values.companyName, address: values.companyAddress }
      incomingInvoice.recipientCompany = availablePossibleRecipients.value.find(x => x.id === values.recipientCompanyNumber)
      incomingInvoice.dateOfPayment = values.dateOfPayment
      incomingInvoice.copyRecipients = []
      if (values.creditCard && !values.creditCard.isArchived) {
        incomingInvoice.creditCard = values.creditCard
      }
      values.copyRecipients.forEach((copyRecipient) => {
        incomingInvoice.copyRecipients.push({ id: copyRecipient.cprId, name: copyRecipient.cprName })
      })
      incomingInvoice.emailApproval = []
      values.approvingPersons.forEach((approvingPerson) => {
        incomingInvoice.emailApproval.push({ id: approvingPerson.cprId, name: approvingPerson.cprName })
      })

      // invoice currency
      incomingInvoice.currency = availableCurrencies.value.find(curr => curr.id === values.crrId)
    }

    const loadInvoiceGroups = (groups) => {
      // load incoming invoice groups
      incomingInvoice.incomingInvoiceGroups = []
      groups.forEach((group) => {
        group.invoiceGroup = availableInvoiceGroups.value.find(invoiceGroup => invoiceGroup.id === group.groupId)
        incomingInvoice.incomingInvoiceGroups.push(group)
      })

      // add empty invoice group
      const iig = reactive({} as IncomingInvoiceGroup)
      incomingInvoice.incomingInvoiceGroups.push(iig)
    }

    const loadInvoiceToursAndConcerts = (tours) => {
      incomingInvoice.incomingInvoiceTours = tours;
      tourLoop:
        for (const tour of tours) {
          for (const container of tour.incomingInvoiceContainers) {
            if (!container.incomingInvoicePositions) {
              container.incomingInvoicePositions = []
            }

            incomingInvoice.incomingInvoiceGroups.forEach((group) => {
              let existingPosItems = container.incomingInvoicePositions.filter((posItem) => {
                return group.id === posItem.groupId
              })
              if (existingPosItems.length === 0) {
                let incomingInvoicePosition = reactive({} as IncomingInvoicePosition)
                incomingInvoicePosition.groupId = group.id
                container.incomingInvoicePositions.push(incomingInvoicePosition)
              }

              // check if a group needs to be removed from positions
              container.incomingInvoicePositions.forEach((position, index) => {
                let existingGroupItems = incomingInvoice.incomingInvoiceGroups.filter((group) => {
                  let groupId = group.invoiceGroup ? group.invoiceGroup.id : 0
                  return groupId === position.groupId;
                })

                if (existingGroupItems.length === 0) {
                  container.incomingInvoicePositions.splice(index, 1);
                }
              })
            })

            checkOrUncheckContainer(container)

            for (const concert of container.incomingInvoiceConcerts) {
              for (const position of concert.incomingInvoicePositions) {
                if (!!position.number) {
                  incomingInvoice.flgUseCostPositionNumber = true
                  break tourLoop
                }
              }
            }
          }
        }
    }

    const loadInvoicePdf = async (loadedPdf) => {
      await incomingInvoiceService.value.getFileByGuid(loadedPdf.iif_guid).then(pdfData => {
        if (pdfData.success) {
          incomingInvoice.invoicePdf = new File([pdfData.data], loadedPdf.iif_original_name, { type: 'application/pdf' })
        }
      })
    }

    const loadInvoiceAttachments = async (loadedAttachments) => {
      incomingInvoice.attachments = []
      for (const attachment of loadedAttachments) {
        await incomingInvoiceService.value.getFileByGuid(attachment.iif_guid).then(attachmentData => {
          if (attachmentData.success) {
            let fileEnding = attachment.iif_original_name.substring(attachment.iif_original_name.length - 3, attachment.iif_original_name.length)
            let fileType;
            switch (fileEnding) {
              case 'jpg':
                fileType = 'image/jpeg'
                break
              case 'png':
                fileType = 'image/png'
                break
              default:
                // assume pdf if no file type can be guessed/it's a pdf
                fileType = 'application/pdf'
                break
            }
            incomingInvoice.attachments.push(new File([attachmentData.data], attachment.iif_original_name, { type: fileType }))
          }
        })
      }
    }

    const validate = async () => {
      await myForm.value.validate()
    }

    const formatDate = () => {
      return formatForCalendar(incomingInvoice.date)
    }

    const formatDateSentToAccounting = () => {
      return formatForCalendar(incomingInvoice.dateSentToAccounting)
    }

    const formatDateOfPayment = () => {
      return formatForCalendar(incomingInvoice.dateOfPayment)
    }

    const saveInvoice = async () => {
      isSaving.value = true
      await validate()

      if (isValid.value && errors.value.length === 0) {
        incomingInvoiceService.value.sendAndSave(incomingInvoice).then(data => {
          if (!!data && !!data.data && data.data.success) {
            store.commit("unsetDirty");
            store.commit('setSuccessNotification', 'Die Eingangsrechnung wurde erfolgreich übermittelt.')
            isSaved.value = true
          } else {
            isSaving.value = false
            store.commit('setErrorNotification', 'Die Eingangsrechnung konnte nicht übermittelt werden.')
          }
        });
      } else {
        isSaving.value = false
        store.commit('setErrorNotification', 'Bitte prüfen Sie die Felder auf Fehler.')
      }
    }

    const customFilter = () => {
      // this just disables the vuetify filter, filtering items is done via axios
      return true
    }

    const possibleRecipients = () => {
      return availablePossibleRecipients.value
    }

    const invoiceGroups = (id) => {
      return availableInvoiceGroups.value.filter((item) => {
        return id === item.id || !incomingInvoice.incomingInvoiceGroups.map(i => i.invoiceGroup ? i.invoiceGroup.id : null).includes(item.id)
      })
    }

    const addTour = () => {
      incomingInvoiceService.value.getTour(selectedTour.value.id).then(data => {
        let tourExists = incomingInvoice.incomingInvoiceTours.filter((tourItem) => {
          return data.data.id === tourItem.tourId
        })

        if (tourExists.length === 0) {
          let iit = data.data as IncomingInvoiceTour
          if (iit.id !== null) {
            iit.tourId = iit.id
          }

          // reset the id to fix id from api
          iit.id = null

          incomingInvoice.incomingInvoiceTours.push(iit)
          if (!incomingInvoice.selectedTour) {
            incomingInvoice.selectedTour = 'tab-0'
          }

          incomingInvoice.incomingInvoiceGroups.forEach((item) => {
            if (item.invoiceGroup) {
              let groupId = item.invoiceGroup.id

              incomingInvoice.incomingInvoiceTours.forEach((tour) => {
                tour.incomingInvoiceContainers.forEach((container) => {
                  container.incomingInvoiceConcerts.forEach((concert) => {
                    let exists = concert.incomingInvoicePositions.filter((posItem) => {
                      return groupId === posItem.groupId
                    })

                    if (exists.length === 0) {
                      let incomingInvoicePosition = reactive({} as IncomingInvoicePosition)
                      incomingInvoicePosition.groupId = groupId
                      incomingInvoicePosition.amount = null
                      incomingInvoicePosition.desc = null
                      incomingInvoicePosition.number = null
                      concert.incomingInvoicePositions.push(incomingInvoicePosition)
                    }
                  })

                  // check if a group needs to be added to positions
                  let existingPosItems = container.incomingInvoicePositions.filter((posItem) => {
                    return groupId === posItem.groupId
                  })
                  if (existingPosItems.length === 0) {
                    let incomingInvoicePosition = reactive({} as IncomingInvoicePosition)
                    incomingInvoicePosition.groupId = groupId
                    incomingInvoicePosition.amount = null
                    incomingInvoicePosition.desc = null
                    incomingInvoicePosition.number = null
                    container.incomingInvoicePositions.push(incomingInvoicePosition)
                  }
                })
              })
            }
          })
        }

        tourItems.value = []
      })

      tourSearch.value = null
      selectedTour.value = null

      showFocusElem.value = true
      window.setTimeout(function () {
        tourSelectedElem.value.focus()
        showFocusElem.value = false
      }, 20)
    }

    const incomingInvoiceNumberRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    const incomingInvoiceDateRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    const incomingInvoiceCompanyRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    const incomingInvoiceRecipientCompanyRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    const incomingInvoiceTotalAmountRule = ref([
      v => !!v || 'Darf nicht leer sein',
    ])

    const checkDuplicate = async () => {
      let duplicate = false

      if (incomingInvoice.number && incomingInvoice.company && incomingInvoice.company.id) {
        duplicate = await incomingInvoiceService.value.checkDuplicateInvoiceNumberAndCompany(incomingInvoice.number, incomingInvoice.company.id).then(data => {
          return data.success;
        })
      }

      showDuplicateWarning.value = duplicate
    }

    const reloadPage = () => {
      window.location.reload();
    }

    const backToSystem = () => {
      window.location.href = import.meta.env.VITE_CONCERTON_URL + '/incoming-invoice/index';
    }

    const linkToCompanyCreate = () => {
      let url = import.meta.env.VITE_CONCERTON_URL + '/company/create?';

      if (incomingInvoice.vatId) {
        url += '&vatId=' + encodeURIComponent(incomingInvoice.vatId)
      }
      if (incomingInvoice.iban) {
        url += '&iban=' + encodeURIComponent(incomingInvoice.iban)
      }
      if (incomingInvoice.senderCompany.name) {
        url += '&name=' + encodeURIComponent(incomingInvoice.senderCompany.name)
      }
      if (incomingInvoice.senderCompany.street) {
        url += '&street=' + encodeURIComponent(incomingInvoice.senderCompany.street)
      }
      if (incomingInvoice.senderCompany.zipcode) {
        url += '&zipcode=' + encodeURIComponent(incomingInvoice.senderCompany.zipcode)
      }
      if (incomingInvoice.senderCompany.city) {
        url += '&city=' + encodeURIComponent(incomingInvoice.senderCompany.city)
      }
      if (incomingInvoice.senderCompany.country) {
        url += '&country=' + encodeURIComponent(incomingInvoice.senderCompany.country)
      }
      if (incomingInvoice.senderCompany.email) {
        url += '&email=' + encodeURIComponent(incomingInvoice.senderCompany.email)
      }
      if (incomingInvoice.senderCompany.phone) {
        url += '&phone=' + encodeURIComponent(incomingInvoice.senderCompany.phone)
      }
      if (incomingInvoice.senderCompany.fax) {
        url += '&fax=' + encodeURIComponent(incomingInvoice.senderCompany.fax)
      }
      if (incomingInvoice.senderCompany.homepage) {
        url += '&homepage=' + encodeURIComponent(incomingInvoice.senderCompany.homepage)
      }

      window.open(url, '', 'width=1500, height=800')
    }

    const linkToSenderEdit = () => {
      let url = import.meta.env.VITE_CONCERTON_URL + '/company/edit?id=' + incomingInvoice.company.id;
      window.open(url, '', 'width=1500, height=800')
    }

    const debugButton = () => {
      // checkDuplicate()
      // checkForErrors()
      incomingInvoice.totalAmount = 1230.23
    }

    const checkOrUncheckContainer = (container) => {
      let countChecked = 0
      let countUnchecked = 0

      container.incomingInvoiceConcerts.forEach((concert) => {
        if (concert.isSelected) {
          countChecked++
        } else {
          countUnchecked++
        }
      })

      if (countUnchecked === 0) {
        container.selectedState = true
      } else if (countChecked === 0) {
        container.selectedState = false
      } else {
        container.selectedState = null
      }
    }

    watch(() => personSearch,
      (currentValue) => {
        clearTimeout(personSearchTimer.value)
        personSearchTimer.value = setTimeout(() => {
          if (currentValue.value.length > 2) {
            personLoading.value = true

            incomingInvoiceService.value.findCompanyPersonRelations(currentValue.value).then(data => {
              let dataWithDisabled = []

              data.data.forEach((item) => {
                dataWithDisabled.push({ id: item.id, name: item.name, props: { disabled: !item.hasEmail} })
              })

              personItems.value = dataWithDisabled
            })

            personLoading.value = false

          } else {
            personItems.value = []
          }
        }, 500)
      },
      { deep: true }
    );

    watch(() => copyRecipientSearch,
      (currentValue) => {
        clearTimeout(copyRecipientSearchTimer.value)
        copyRecipientSearchTimer.value = setTimeout(() => {
          if (currentValue.value.length > 2) {
            copyRecipientLoading.value = true

            incomingInvoiceService.value.findCompanyPersonRelations(currentValue.value).then(data => {
              let dataWithDisabled = []

              data.data.forEach((item) => {
                dataWithDisabled.push({ id: item.id, name: item.name, props: { disabled: !item.hasEmail} })
              })

              copyRecipientItems.value = dataWithDisabled
            })

            copyRecipientLoading.value = false

          } else {
            copyRecipientItems.value = []
          }
        }, 500)
      },
      { deep: true }
    );

    watch(() => companySearch,
      (currentValue) => {
        clearTimeout(companySearchTimer.value)
        companySearchTimer.value = setTimeout(() => {
          if (currentValue.value.length > 2) {
            companyLoading.value = true

            incomingInvoiceService.value.findCompanies(currentValue.value).then(data => {
              companyItems.value = data.data
            })

            companyLoading.value = false

          } else {
            companyItems.value = []
          }
        }, 500)
      },
      { deep: true }
    );

    watch(() => tourSearch,
      (currentValue) => {
        clearTimeout(tourSearchTimer.value)
        tourSearchTimer.value = setTimeout(() => {
          if (currentValue.value.length > 2) {
            incomingInvoiceService.value.findTours(currentValue.value).then(data => {
              tourItems.value = data.data
            })
          } else {
            tourItems.value = []
          }
        }, 500)
      },
      { deep: true }
    );

    function formatForCalendar(dateToFormat) {

      let parts = dateToFormat.split('.');
      let myDate = new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));

      // ignore padStart error
      // @ts-ignore
      const day = myDate.getDate().toString().padStart(2, '0');

      // ignore padStart error
      // @ts-ignore
      const month = (myDate.getMonth() + 1).toString().padStart(2, '0');

      const year = myDate.getFullYear();

      return `${day}.${month}.${year}`;
    }

    function getCurrentDate() {
      const current = new Date();
      return `${current.getDate()}.${current.getMonth() + 1}.${current.getFullYear()}`;
    }

    function checkForErrors() {
      errors.value = []

      // check if the chosen company has an address
      if (incomingInvoice.company && !incomingInvoice.company.address) {
        errors.value.push('Bei dem ausgewählten Rechnungssteller ist keine Adresse hinterlegt.')
      }

      // check if an amount was set
      if (!incomingInvoice.totalAmount || incomingInvoice.totalAmount === 0) {
        errors.value.push('Die Gesamtsumme (netto) muss gesetzt sein.')
      }

      // check if an amount is set for every group
      if (incomingInvoice.incomingInvoiceGroups && incomingInvoice.incomingInvoiceGroups.length > 0) {
        incomingInvoice.incomingInvoiceGroups.forEach((groupItem) => {
          if ((!groupItem.amount || groupItem.amount === 0) && groupItem.invoiceGroup) {
            errors.value.push('Für die Gruppe ' + groupItem.invoiceGroup.name + ' müssen Kosten gesetzt werden.')
          }
        })
      }

      // check if an invoice pdf was added
      if (!incomingInvoice.invoicePdf) {
        errors.value.push('Es muss noch ein Rechnungs-PDF hochgeladen werden')
      } else {
        if (incomingInvoice.invoicePdf.type !== 'application/pdf') {
          errors.value.push('Rechnung muss ein PDF sein.')
        }
      }

      // check if an attachment was added
      if (incomingInvoice.attachments && incomingInvoice.attachments.length > 0) {
        incomingInvoice.attachments.forEach((attachedFile) => {
          if (attachedFile.type !== 'application/pdf' && attachedFile.type !== 'image/png' && attachedFile.type !== 'image/jpeg' && attachedFile.type !== 'image/tiff') {
            errors.value.push('Anhänge erlauben nur PDF, JPG, PNG und TIFF Dateien.')
          }
        })
      }

      // check if at least one production is selected
      if (incomingInvoice.incomingInvoiceTours.length === 0) {
        errors.value.push('Es muss mindestens eine Tournee gewählt sein')
      }

      // check if at least one show was selected
      let hasSelectedConcerts = false
      incomingInvoice.incomingInvoiceTours.forEach((tourItem) => {
        tourItem.incomingInvoiceContainers.forEach((container) => {
          let exists = container.incomingInvoiceConcerts.filter((item) => {
            return item.isSelected;
          })

          if (exists.length > 0) {
            hasSelectedConcerts = true
          }
        })
      })

      if (!hasSelectedConcerts) {
        errors.value.push('Es muss mindestens ein Konzert ausgewählt sein')
      }

      // check if total sum matches group sums
      let sumGroups = 0;
      let totalAmount = incomingInvoice.totalAmount || 0;

      if (incomingInvoice.incomingInvoiceGroups) {
        sumGroups = incomingInvoice.incomingInvoiceGroups.reduce((sum, item) => {
          return sum + (item.amount || 0)
        }, 0)

        sumGroups = Math.round(sumGroups * 1000000) / 1000000
      }

      if (totalAmount !== sumGroups) {
        errors.value.push('Die Gesamtsumme muss der Summe der Rechnungsgruppen entsprechen')
      }

      // check if there is a rest
      let hasRest = false
      if (incomingInvoice.incomingInvoiceGroups) {
        incomingInvoice.incomingInvoiceGroups.forEach((groupItem) => {
          if (groupItem.invoiceGroup) {
            let groupId = groupItem.invoiceGroup.id
            let sum = 0
            incomingInvoice.incomingInvoiceTours.forEach((tourItem) => {
              tourItem.incomingInvoiceContainers.forEach((container) => {
                container.incomingInvoiceConcerts.forEach((concertItem) => {
                  if (concertItem.isSelected) {
                    concertItem.incomingInvoicePositions.forEach((position) => {
                      if (position.groupId === groupId && position.amount) {
                        sum += parseFloat(position.amount.toFixed(6))
                      }
                    })
                  }
                })
              })
            })

            if ((groupItem.amount ?? 0) !== parseFloat(sum.toFixed(6))) {
              hasRest = true
            }
          }
        })
      }

      if (hasRest) {
        errors.value.push('Es wurden nicht die gesamten Kosten auf die Konzerte verteilt')
      }
    }

    return {
      incomingInvoice,
      error,
      myForm,
      validate,
      isValid,
      guid,
      isLoading,
      formatDate,
      formatDateSentToAccounting,
      formatDateOfPayment,
      personLoading,
      personItems,
      personSearch,
      copyRecipientLoading,
      copyRecipientItems,
      copyRecipientSearch,
      companyLoading,
      companyItems,
      companySearch,
      tourLoading,
      tourItems,
      tourSearch,
      selectedTour,
      invoiceGroups,
      possibleRecipients,
      availableInvoiceGroups,
      availablePossibleRecipients,
      availableCurrencies,
      availableCreditCards,
      addTour,
      customFilter,
      saveInvoice,
      tourSelectedElem,
      showFocusElem,
      noResultsText,
      incomingInvoiceNumberRule,
      incomingInvoiceCompanyRule,
      incomingInvoiceRecipientCompanyRule,
      incomingInvoiceDateRule,
      incomingInvoiceTotalAmountRule,
      errors,
      isSaved,
      reloadPage,
      backToSystem,
      debugEnabled,
      debugButton,
      linkToCompanyCreate,
      linkToSenderEdit,
      checkDuplicate,
      showDuplicateWarning,
      isSaving
    }
  },
}

</script>
